import React from 'react';
import { Link } from 'react-router-dom';

import Fields from 'components/Form/Fields';
import Page from 'components/Page';
import Form from 'components/Form/Form';
import FormCard from 'components/Form/FormCard';

const fields = [
  {
    name: 'emailAddress',
    label: 'Email Address',
    input: 'input',
    type: 'email',
    placeholder: 'Email Address',
    containerClasses: ['form-group'],
    classes: ['form-control'],
    describedBy: 'emailAddress',
  },
  {
    name: 'password',
    label: 'Password',
    input: 'input',
    type: 'password',
    placeholder: 'Password',
    containerClasses: ['form-group'],
    classes: ['form-control'],
    describedBy: 'password',
  },
  {
    name: 'rememberMe',
    label: 'Remember me on this computer.',
    labelClasses: ['sr-only'],
    input: 'input',
    type: 'checkbox',
    options: [{ value: true, label: 'Remember me on this computer.' }],
    containerClasses: ['form-group'],
    classes: ['custom-control', 'custom-checkbox'],
    describedBy: 'rememberMe',
  },
  {
    name: 'redirectPath',
    input: 'input',
    type: 'hidden',
    containerClasses: ['form-group', 'hidden'],
    classes: ['form-control'],
    describedBy: 'redirectPath',
    disabled: true,
  },
];

function createAccountUrl(redirectPath) {
  if (redirectPath && redirectPath.length > 0) {
    return `/create-account?returnTo=${redirectPath}`;
  } else {
    return '/create-account';
  }
}

const LoginFields = props => <Fields inputs={fields} {...props} />;

class LoginForm extends React.Component {
  state = {
    formValues: {
      emailAddress: '',
      password: '',
      rememberMe: false,
      redirectPath: '',
    },
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      formValues: { ...this.state.formValues, [name]: value },
    });
  };

  render() {
    const { handleSubmit } = this.props;
    let { formValues } = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    formValues['redirectPath'] = queryParams.get('returnTo') || '';

    return (
      <Page>
        <div className="row mt-5 pt-5">
          <div className="col-md-9 col-lg-6 m-auto">
            <FormCard>
              <div className="row justify-content-center py-md-5">
                <div className="col-10 col-md-8">
                  <h2 className="text-center">Log In</h2>
                  <Form
                    formToEvaluate={formValues}
                    defaultForm={formValues}
                    updater={this.handleInputChange}
                    component={LoginFields}
                    {...this.props}
                  />
                  <button
                    className="btn btn-green col-7 my-4 d-block mx-auto"
                    onClick={() => handleSubmit(formValues)}
                  >
                    Log In
                  </button>
                  <div className="text-center text-dark">
                    <Link to={createAccountUrl(formValues['redirectPath'])}>
                      Sign up
                    </Link>
                  </div>
                  <div className="text-center text-dark">
                    <Link to="/reset-password">Forgot your password?</Link>
                  </div>
                  <div className="text-center text-dark">
                    <a
                      href="https://housing-hub-email.paperform.co/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Forgot your email?
                    </a>
                  </div>
                  <div className="text-center text-dark">
                    <a
                      href="https://housinglink.helpjuice.com/housinghub-help-for-applicants"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Help
                    </a>
                  </div>
                </div>
              </div>
            </FormCard>
          </div>
        </div>
      </Page>
    );
  }
}

export default LoginForm;
