import { handleJSONResponse, apiURL, getToken } from 'utils/api';

const handleMarkAsRead = ({
  applicantId,
  handleAlert,
  id,
  handleFetchNotifications
}) => {
  const JWT = getToken();

  fetch(apiURL(`/notifications/${id}/mark_as_read`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...JWT.token
    },
    body: JSON.stringify({
      applicant_id: applicantId
    })
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .then(() => {
      handleFetchNotifications();
    })
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e).join(', ');
      }

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'danger'
      });
    });
};

const handleMarkAsUnread = ({
  applicantId,
  handleAlert,
  id,
  handleFetchNotifications
}) => {
  const JWT = getToken();

  fetch(apiURL(`/notifications/${id}/mark_as_unread`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...JWT.token
    },
    body: JSON.stringify({
      applicant_id: applicantId
    })
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .then(() => {
      handleFetchNotifications();
    })
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e).join(', ');
      }

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'danger'
      });
    });
};

export default { handleMarkAsRead, handleMarkAsUnread };
