import React from 'react';
import Fields from 'components/Form/Fields';
import LocationLookup from 'components/Form/LocationLookup';
import ArrowButton from 'components/Icon/ArrowButton';
import HouseholdMembers from '../../../components/Form/HouseholdMembers';

export const houseHoldSize = [
  {
    name: 'household_size',
    label: 'How many people live in your household?',
    labelClasses: [
      'col-form-label',
      'col-md-7',
      'text-md-right',
      'text-center',
    ],
    input: 'input',
    type: 'text',
    containerClasses: ['form-group', 'intake-form-row'],
    classes: ['form-control', 'text-center', 'stripped', 'col-md-1', 'p-0'],
    describedBy: 'household_size',
    disabled: true,
  },
];

export const fields1 = [
  {
    name: 'household_income',
    label: 'What is your household monthly income?',
    labelClasses: [
      'col-form-label',
      'col-md-7',
      'text-md-right',
      'text-center',
    ],
    input: 'input',
    type: 'number',
    min: '0',
    containerClasses: ['form-group', 'intake-form-row'],
    classes: ['form-control', 'col-md-2'],
    describedBy: 'household_income',
  },
  {
    name: 'preferred_bedroom_sizes',
    label: 'How many bedrooms?',
    labelClasses: [
      'col-form-label',
      'col-md-7',
      'text-md-right',
      'text-center',
    ],
    input: 'select',
    type: 'select',
    options: [
      {
        label: 'Studio',
        value: 0,
      },
      {
        label: '1 Bedroom',
        value: 1,
      },
      {
        label: '2 Bedroom',
        value: 2,
      },
      {
        label: '3 Bedroom',
        value: 3,
      },
      {
        label: '4+ Bedroom',
        value: 4,
      },
    ],
    containerClasses: ['form-group', 'intake-form-row'],
    classes: ['react-select', 'col-md-3', 'p-0'],
    describedBy: 'preferred_bedroom_sizes',
    isMulti: true,
    size: 3,
  },
];

export const fields2 = [
  {
    name: 'senior_citizen',
    label: 'Senior (55+)',
    labelClasses: [
      'col-form-label',
      'col-md-7',
      'text-md-right',
      'text-center',
    ],
    input: 'input',
    type: 'radio',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    containerClasses: [
      'form-group',
      'intake-form-row',
      'justify-content-center',
      'justify-content-md-start',
    ],
    classes: ['custom-control', 'custom-radio', 'col-md-1', 'col-2'],
    describedBy: 'senior_citizen',
  },
  {
    name: 'disabled',
    label: 'Disabled',
    labelClasses: [
      'col-form-label',
      'col-md-7',
      'text-md-right',
      'text-center',
    ],
    input: 'input',
    type: 'radio',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    containerClasses: [
      'form-group',
      'intake-form-row',
      'justify-content-center',
      'justify-content-md-start',
    ],
    classes: ['custom-control', 'custom-radio', 'col-md-1', 'col-2'],
    describedBy: 'disabled',
  },
  {
    name: 'homeless',
    label: 'Experiencing Homelessness',
    labelClasses: [
      'col-form-label',
      'col-md-7',
      'text-md-right',
      'text-center',
    ],
    input: 'input',
    type: 'radio',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    containerClasses: [
      'form-group',
      'intake-form-row',
      'justify-content-center',
      'justify-content-md-start',
    ],
    classes: ['custom-control', 'custom-radio', 'col-md-1', 'col-2'],
    describedBy: 'homeless',
  },
];

const IntakeForm = props => {
  const existingHouseholdMembers = props.formValues.household_members.filter(
    member => member.id
  );

  const disableHouseholdSizeDecrease =
    props.formValues.household_members.length > 0 &&
    existingHouseholdMembers.length ===
      props.formValues.household_members.length;

  /*
   * For household_size, displays the increase/decrease buttons that go before/after the input.
   * For householde_income, displays the dollar sign before the input.
   * For all other fields, does nothing.
   */
  const inputModifier = (field, before) => {
    const val = props.formValues[field] || 0;
    let updater;
    if (field === 'household_size') {
      if (before) {
        updater = () => {
          props.update({
            target: {
              value: val + 1,
              name: field,
            },
          });
          props.touched && props.touched(field);
        };
      } else {
        updater = () => {
          val !== 0 &&
            !disableHouseholdSizeDecrease &&
            props.update({
              target: {
                value: val - 1,
                name: field,
              },
            });
          props.touched && props.touched(field);
        };
      }
      return (
        <div
          className="d-flex mx-auto m-md-0"
          role="button"
          tabIndex="0"
          aria-label={`{before ? "increase" : "decrease"} household size`}
          name="household_size"
          onKeyPress={updater}
          onClick={updater}
        >
          {before ? <ArrowButton up="true" /> : <ArrowButton />}
        </div>
      );
    } else if (field === 'household_income') {
      if (before) {
        return (
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
        );
      }
    }
    return null;
  };

  const removeLocation = (e, location) => {
    let locations = props.formValues.locations;
    locations = locations.filter(
      storedLocation => storedLocation.id !== location.id
    );
    props.update({
      target: {
        type: 'text',
        value: locations,
        name: 'locations',
      },
    });
    props.touched && props.touched('locations');
  };

  return (
    <React.Fragment>
      <Fields
        inputs={houseHoldSize}
        inputModifier={(name, before) => inputModifier(name, before)}
        {...props}
      />
      {!props.hideHouseholdMembers && <HouseholdMembers {...props} />}
      <Fields
        inputs={fields1}
        inputModifier={(name, before) => inputModifier(name, before)}
        {...props}
      />
      <hr className="col-10 mt-4" />
      <h5 className="text-center">Where do you want to live?</h5>
      <p className="text-center" id="location-lookup-label">
        Enter cities, counties, or states
      </p>
      <div className="form-group row">
        <div className="col-6 m-auto px-0">
          <LocationLookup {...props} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-6 m-auto">
          {props.formValues.locations &&
            props.formValues.locations.map(location => (
              <button
                key={`selected-location-${location.id}`}
                id={`selected-location-${location.id}`}
                type="button"
                className="btn btn-blue m-1"
                onClick={e => removeLocation(e, location)}
              >
                {location.attributes.short_name}{' '}
                <span className="close">
                  <span className="text-white ml-3">&times;</span>
                </span>
                <span className="sr-only">selected location</span>
              </button>
            ))}
        </div>
      </div>
      <div>
        <hr className="col-10 mt-4" />
        <h5 className="text-center">
          Only show me housing for (Check all that apply):
        </h5>
      </div>
      <Fields
        inputs={fields2}
        inputModifier={(name, before) => inputModifier(name, before)}
        {...props}
      />
    </React.Fragment>
  );
};

export default IntakeForm;
